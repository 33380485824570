import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
        <p className="copyright">&copy; Sylvain Foucault. Tous droits réservés.</p>
    </footer>    
  )
}

export default Footer