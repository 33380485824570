import React, {useRef} from 'react'
import './portfolioItem.css'
import PetiteCroix from '../../assets/images/cross.png'

const PortfolioItem = (props) => {

    const overlay = useRef(null);
    const croix = useRef(null);
    const clickOverlay = function (){
        overlay.current.classList.add("derouleSite");
        croix.current.style.opacity = 1;
    }            
    const clickCroix = function () {
        overlay.current.style.display = "none";
        overlay.current.classList.remove("derouleSite"); 
    }

    const MCvideo = useRef(null);
    const clickBouton4Lire = function () {
        MCvideo.current.play();
    }
    const clickBouton4Pause = function () {
        MCvideo.current.pause();
    }
    const clickBouton4Agrandir = function () {
        MCvideo.current.requestFullscreen();
    }

    let media;
    let boutons;
    let itemOverlay;
    let lien;
    if (props.type === "image") {

        media = <img src={props.media.src} alt={props.media.alt} id={props.media.id} />
        boutons = <button className="boutonVoir" id={props.boutons.id} onClick={clickOverlay}>{props.boutons.texte}</button>
        itemOverlay = <div id={props.overlay.id} ref={overlay}>
                        <img src={PetiteCroix} alt="croix pour fermer" className="petiteCroix" id={props.overlay.croixId} ref={croix} onClick={clickCroix}/>
                        <img src={props.overlay.src} className="centrerGrandeImage" id={props.overlay.imgId} alt={props.overlay.imgAlt} />
                    </div>

    } else if (props.type === "video") {
        
        media = <video width="100%" height="100%" id={props.media.id} poster={props.media.poster} ref={MCvideo}>
            <source src={props.media.src} type="video/mp4"/>
        </video>
        boutons = <div className="boutonsVideo">
                    <button className="boutonVoir" id={props.boutons[0].id} onClick={clickBouton4Lire}>{props.boutons[0].texte}</button>
                    <button className="boutonVoir" id={props.boutons[1].id} onClick={clickBouton4Pause}>{props.boutons[1].texte}</button>
                    <button className="boutonVoir" id={props.boutons[2].id} onClick={clickBouton4Agrandir}>{props.boutons[2].texte}</button>
                </div>
    } else if (props.type === "lien") {

        media = <img src={props.media.src} alt={props.media.alt} id={props.media.id} />
        lien = <a href={props.lien.href} target="_blank" rel="noreferrer" className="boutonVoir" id={props.lien.id}>{props.lien.texte}</a>
    }

    return (
        <>
            <div className="item" ref={props.refItem}>
                {media}
                <div className="item-overlay">
                    <div>
                        <h3>{props.texte.titre}</h3>
                        {props.texte.paragraphe}
                    </div>
                    {boutons}
                    {lien}
                </div>
            </div>
            {itemOverlay}            
        </>
    )

}

export default PortfolioItem