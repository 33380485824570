import React from 'react'
import './header.css'

const Header = () => {
  return (
    <header>
      
    </header>
  )
}

export default Header