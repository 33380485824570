import React from 'react'
import './contact.css'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_e5t7smi', 'template_rrqpx7y', form.current, 'user_NDoWq15zeKNPQlCmOdwdX')
          .then((result) => {
              console.log(result.text);
              alert("Message envoyé !");
              e.target.reset();
          }, (error) => {
              console.log(error.text);
              alert("Une erreur s'est produite.");
          });

          
          
      };

  return (
    <section id='contact'>
        <div className="skew-c"></div>
        <h2 className="contactTitre">CONTACT</h2>
        <h3 className="contactMe">Pour m'envoyer un message : </h3>
    
        <div className="contactContainer">
            <div className='contact__options'>
                <a href='mailto:contact@sylvainfoucault.com' target='_blank' rel="noreferrer">
                    <article className='contact__option'>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="contact__option-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"></path></svg>
                        <h3>Email</h3>                    
                        <span>contact@sylvainfoucault.com</span>
                    </article>
                </a>
                <a href='https://facebook.com/foucaultsylvain' target='_blank' rel="noreferrer">
                    <article className='contact__option'>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="contact__option-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path fillRule="nonzero" d="M7.764 19.225c.59-.26 1.25-.309 1.868-.139.77.21 1.565.316 2.368.314 4.585 0 8-3.287 8-7.7S16.585 4 12 4s-8 3.287-8 7.7c0 2.27.896 4.272 2.466 5.676a2.8 2.8 0 0 1 .942 2.006l.356-.157zM12 2c5.634 0 10 4.127 10 9.7 0 5.573-4.366 9.7-10 9.7a10.894 10.894 0 0 1-2.895-.384.8.8 0 0 0-.534.039l-1.984.876a.8.8 0 0 1-1.123-.707l-.055-1.78a.797.797 0 0 0-.268-.57C3.195 17.135 2 14.617 2 11.7 2 6.127 6.367 2 12 2zM5.995 14.537l2.937-4.66a1.5 1.5 0 0 1 2.17-.4l2.336 1.75a.6.6 0 0 0 .723 0l3.155-2.396c.421-.319.971.185.689.633l-2.937 4.66a1.5 1.5 0 0 1-2.17.4l-2.336-1.75a.6.6 0 0 0-.723 0l-3.155 2.395c-.421.319-.971-.185-.689-.633z"></path></g></svg>
                        <h3>Messenger</h3>                    
                        <span>facebook.com/foucaultsylvain</span>
                    </article>
                </a>
                <a href='https://api.whatsapp.com/send?phone+33768766012' target='_blank' rel="noreferrer">
                    <article className='contact__option'>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="contact__option-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path></svg>
                        <h3>WhatsApp</h3>                    
                        <span>+33 7 68 76 60 12</span>
                    </article>
                </a>
            </div>
            <form ref={form} onSubmit={sendEmail} className="formContact">
                <input type="text" name="name" placeholder="Votre nom" required/>
                <input type="email" name="email" placeholder="Votre email" required/>
                <textarea name="message" rows="7" placeholder="Votre message" required></textarea>
                <button type="submit" className="bouton">Envoyer le message</button>
            </form>
        </div>
        <div className="skew-c2"></div>
    </section>
  )
}

export default Contact