import React, { useEffect, useRef, useState } from 'react'
import './portfolio.css'

import GitHubLogo from '../../assets/images/github.png'
import Linkedin from '../../assets/images/linkedin.png'
import UpArrow from '../../assets/images/up-arrow.png'

import HTMLLogo from '../../assets/images/logoSkills/htmlCssJS.png'
import PHPLogo from '../../assets/images/logoSkills/phpLogo.png'
import SymfonyLogo from '../../assets/images/logoSkills/symfonyLogo.webp'
import WordpressLogo from '../../assets/images/logoSkills/wordpressLogo.png'
import JavaLogo from '../../assets/images/logoSkills/javaLogo2.jpg'
import ReactLogo from '../../assets/images/logoSkills/react.png'

//import AirGoRun from '../../assets/images/airGoRun.png'
import Bataille from '../../assets/images/Bataille/videoBataille.mp4'
//import ContactFormAMP from '../../assets/images/contactFormAMP.png'
import DemoItTraining from '../../assets/images/ItTraining/demoItTraining.mp4'
import BlogAPI from '../../assets/images/blogAPI.png'
import MielConnect from '../../assets/images/MielConnect/2022-06-10_mielConnect.mp4'
import RCMortagne from '../../assets/images/RCMortagne/RCMortagne.jpg'
import RCMortagneLactu from '../../assets/images/RCMortagne/lactu.png'
import PortfolioImg from '../../assets/images/portfolio.png'
import BalanceAppImg from '../../assets/images/balanceApp2.png'
import BalanceAppVid from '../../assets/images/listeDepense.mkv'
import MCVideo from '../../assets/images/MielConnect.png'
import EmojeTaCiteImg from '../../assets/images/EmojeTaCite2.png'
import EmojeTaCiteVid from '../../assets/images/emojeTaCite.mkv'

import PortfolioItem from './PortfolioItem'

const Portfolio = () => {                            

    //const refAirGoRun = useRef();   
    const refBataille = useRef(); 
    const refItTraining = useRef(); 
    //const refContactForm = useRef();    
    const refRcMortagne = useRef();    
    const refMielConnect = useRef();
    const refPortfolioAlt = useRef();
    const refHTMLLogo = useRef();
    const refPHPLogo = useRef();
    const refSymfonyLogo = useRef();
    const refWordpressLogo = useRef();
    const refReactLogo = useRef();
    const refJavaLogo = useRef();
    const refBalanceApp = useRef();
    const refEmojeTaCiteApp = useRef();

    const [refProjectsThroughLogo, setRefsProjects] = useState([]);
    const [refLogos, setRefsLogos] = useState([]);
    
    const [htmlLogoState, setHtmlLogoState] = useState(false);
    const [phpLogoState, setPhpLogoState] = useState(false);
    const [symfonyLogoState, setSymfonyLogoState] = useState(false);
    const [wordpressLogoState, setWordpressLogoState] = useState(false);
    const [reactLogoState, setReactLogoState] = useState(false);
    const [javaLogoState, setJavaLogoState] = useState(false);

    const [logosStatesSetters, setLogosStateSetters] = useState([]);

    useEffect(() => {
        let refProjectsThroughLogoTab = [];
        //refProjectsThroughLogoTab.push(refAirGoRun);
        refProjectsThroughLogoTab.push(refBataille);
        refProjectsThroughLogoTab.push(refItTraining);
        //refProjectsThroughLogoTab.push(refContactForm);
        refProjectsThroughLogoTab.push(refRcMortagne);
        refProjectsThroughLogoTab.push(refMielConnect);
        refProjectsThroughLogoTab.push(refPortfolioAlt);
        refProjectsThroughLogoTab.push(refBalanceApp);
        refProjectsThroughLogoTab.push(refEmojeTaCiteApp);
        setRefsProjects(refProjectsThroughLogoTab);
        let refLogosTabs = [];        
        refLogosTabs.push(refHTMLLogo);
        refLogosTabs.push(refPHPLogo);
        refLogosTabs.push(refSymfonyLogo);
        refLogosTabs.push(refWordpressLogo);
        refLogosTabs.push(refReactLogo);
        refLogosTabs.push(refJavaLogo);
        setRefsLogos(refLogosTabs);
        let logosSetters = [];
        logosSetters.push(setHtmlLogoState);
        logosSetters.push(setPhpLogoState);
        logosSetters.push(setSymfonyLogoState);
        logosSetters.push(setWordpressLogoState);
        logosSetters.push(setReactLogoState);
        logosSetters.push(setJavaLogoState);
        setLogosStateSetters(logosSetters);
    }, []);    

    const [displayBackToLogos, setDisplayBackToLogos] = useState("none");

    function backToLogos() {
        window.location.href = "#portfolio-block";
        setDisplayBackToLogos("none");
    }

    function disparaitreFleche () {
        setDisplayBackToLogos("none"); 
        window.removeEventListener('scroll', disparaitreFleche); 
    }    
    function mepListener () {
        window.addEventListener('scroll', disparaitreFleche);                 
    }
    function gestionListener() {        
        var scrollTimeout = null;    
        if (scrollTimeout) clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function(){mepListener()},1500);        
    }

    /**
     * fonction pour sélectionner l'item en fonction du clic sur les logos, et déselectionnage des autres items
     * @param {Event} événement
     */ 
    const handleHighlight = (e) => {
        
        for (let refProj of refProjectsThroughLogo) {
            refProj.current.style.boxShadow = "1px 1px 10px var(--color5)";
        }
        
        for (let refLogo of refLogos) {
            refLogo.current.style.transform = "scale(1)";
        }        

        const cible = e.target.id;

        switch (cible) {
                            
            case 'htmlLogo':
                
                if(!htmlLogoState){
                    //refAirGoRun.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refPortfolioAlt.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refEmojeTaCiteApp.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refHTMLLogo.current.style.transform = "scale(2)";
                    document.getElementById("portfolio").scrollIntoView();                    
                    for (let i in logosStatesSetters) {
                        if (logosStatesSetters[i] === setHtmlLogoState) {
                            logosStatesSetters[i](true);
                        } else {
                            logosStatesSetters[i](false);
                        }                        
                    }
                    setDisplayBackToLogos("block");  
                    gestionListener();                   
                } else {
                    setHtmlLogoState(false);
                }
                break;
            case 'phpLogo':

                if(!phpLogoState){
                    refRcMortagne.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refPHPLogo.current.style.transform = "scale(2)";
                    document.getElementById("rcMortagne").scrollIntoView();                   
                    for (let i in logosStatesSetters) {
                        if (logosStatesSetters[i] === setPhpLogoState) {
                            logosStatesSetters[i](true);
                        } else {
                            logosStatesSetters[i](false);
                        }                        
                    }
                    setDisplayBackToLogos("block");
                    gestionListener(); 
                } else {
                    setPhpLogoState(false);
                }
                break;
            case 'symfonyLogo':

                if(!symfonyLogoState){
                    refRcMortagne.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refSymfonyLogo.current.style.transform = "scale(2)";
                    document.getElementById("rcMortagne").scrollIntoView();                   
                    for (let i in logosStatesSetters) {
                        if (logosStatesSetters[i] === setSymfonyLogoState) {
                            logosStatesSetters[i](true);
                        } else {
                            logosStatesSetters[i](false);
                        }                        
                    }
                    setDisplayBackToLogos("block");
                    gestionListener(); 
                } else {
                    setSymfonyLogoState(false);
                }
                break;
            case 'wordpressLogo':
                
                if(!wordpressLogoState){
                    refMielConnect.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refWordpressLogo.current.style.transform = "scale(2)";
                    document.getElementById("MCvideo").scrollIntoView();                   
                    for (let i in logosStatesSetters) {
                        if (logosStatesSetters[i] === setWordpressLogoState) {
                            logosStatesSetters[i](true);
                        } else {
                            logosStatesSetters[i](false);
                        }                        
                    }
                    setDisplayBackToLogos("block");
                    gestionListener(); 
                } else {
                    setWordpressLogoState(false);
                }
                break;
            case 'reactLogo':
                if(!reactLogoState){
                    //refContactForm.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refBalanceApp.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refReactLogo.current.style.transform = "scale(2)";
                    document.getElementById("balance").scrollIntoView();                   
                    for (let i in logosStatesSetters) {
                        if (logosStatesSetters[i] === setReactLogoState) {
                            logosStatesSetters[i](true);
                        } else {
                            logosStatesSetters[i](false);
                        }                        
                    }
                    setDisplayBackToLogos("block");
                    gestionListener(); 
                } else {
                    setReactLogoState(false);
                }
                break;  
            case 'javaLogo':
                if(!javaLogoState){
                    refBataille.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refItTraining.current.style.boxShadow = "1px 1px 10px 10px var(--color6)";
                    refJavaLogo.current.style.transform = "scale(2)";
                    document.getElementById("batailleVideo").scrollIntoView();                   
                    for (let i in logosStatesSetters) {
                        if (logosStatesSetters[i] === setJavaLogoState) {
                            logosStatesSetters[i](true);
                        } else {
                            logosStatesSetters[i](false);
                        }                        
                    }
                    setDisplayBackToLogos("block");
                    gestionListener(); 
                } else {
                    setJavaLogoState(false);
                }
                break;          
            default:
                console.log("Logo non associé.");
        }                        
        
    }    

  return (
    <section id="presentationSuite">
        <div className="skew-c"></div>
        <div className="portfolio-block" id="portfolio-block">
            <div className='circlingSkillsLogos'>
                <div className="skillsLogos">
                    <div id='htmlLogoContainer' className='logoContainer'>
                        <img src={HTMLLogo} ref={refHTMLLogo} title="HTML-CSS-JS" className="logoSkill" id="htmlLogo" alt="logoHTML" onClick={handleHighlight} />
                    </div>
                    <div id='phpLogoContainer' className='logoContainer'>
                        <img src={PHPLogo} ref={refPHPLogo} title="PHP" className="logoSkill" id="phpLogo" alt="logoPHP" onClick={handleHighlight} />
                    </div>
                    <div id='symfonyLogoContainer' className='logoContainer'>
                        <img src={SymfonyLogo} ref={refSymfonyLogo} title="Symfony" className="logoSkill" id="symfonyLogo" alt="logoSymfony" onClick={handleHighlight} />
                    </div>
                    <div id='wordpressLogoContainer' className='logoContainer'>
                        <img src={WordpressLogo} ref={refWordpressLogo} title="Wordpress" className="logoSkill" id="wordpressLogo" alt="logoWordpress" onClick={handleHighlight}/>
                    </div>
                    <div id='javaLogoContainer' className='logoContainer'>
                        <img src={JavaLogo} ref={refJavaLogo} title="Java" className="logoSkill" id="javaLogo" alt="logoJava" onClick={handleHighlight}/>
                    </div>
                    <div id='reactLogoContainer' className='logoContainer'>
                        <img src={ReactLogo} ref={refReactLogo} title="ReactJS" className="logoSkill" id="reactLogo" alt="logoReact" onClick={handleHighlight} />
                    </div>
                </div> 
            </div>
            <div className="portfolioLine">
                <div className="reseauxLogosBasGauche">
                    <a href="https://github.com/sylvainflt" target="_blank" rel="noreferrer">
                        <img src={GitHubLogo} className="reseauxLogoBas" id="githubLogo" alt="logoGithub"/>
                    </a>
                    <a href="https://www.linkedin.com/in/sylvain-foucault-359419230/" target="_blank" rel="noreferrer">
                        <img src={Linkedin} className="reseauxLogoBas" alt="logoLinkedin"/>
                    </a>
                </div>    
                <h2>Bienvenue sur mon portfolio.</h2>           
                <div></div>
            </div>
                                                           
            <p className="portfolioP">Ce portfolio est constitué de projets réalisés pendant ma formation développeur web, ainsi que pendant le stage et dans mon temps libre.</p>
                    
            <div className="portfolio">
            
                <PortfolioItem type="video"
                                media={{src:Bataille, alt:"une video du jeu de bataille", id:"batailleVideo", poster:""}}
                                texte={{titre:"Jeu de Bataille", paragraphe:<p>Réalisation d'un <strong>jeu de bataille</strong> à 2 joueurs en <strong>Java</strong>. Interface graphique avec <strong>Swing</strong>.</p>}} 
                                boutons={[{id:"bouton4Lire", texte:"Lire"},
                                            {id:"bouton4Pause", texte:"Pause"},
                                            {id:"bouton4Agrandir", texte:"Agrandir"}
                                        ]}  
                                refItem={refBataille}                                      
                                />             
                <PortfolioItem type="video"
                                media={{src:DemoItTraining, alt:"ItTraining site de formations", id:"demoItTraining", poster:""}}
                                texte={{titre:"IT Training", paragraphe:<p>Site de formations. Interface graphique réalisée avec <strong>Angular</strong>, logique métier avec Java et <strong>Spring</strong>, base de données avec <strong>MySQL</strong>.</p>}} 
                                boutons={[{id:"bouton4Lire", texte:"Lire"},
                                            {id:"bouton4Pause", texte:"Pause"},
                                            {id:"bouton4Agrandir", texte:"Agrandir"}
                                        ]}  
                                refItem={refItTraining}                                      
                                />          
                <PortfolioItem type="image"
                                media={{src:BlogAPI, alt:"une api pour un blog", id:"blogAPI"}}
                                texte={{titre:"Blog", paragraphe:<p>Réalisation d'une <strong>API Node.js</strong> avec <strong>TypeScript</strong> et <strong>PostgreSQL</strong> pour une application Blog. Utilisation de l'<strong>ORM Prisma</strong> ainsi que <strong>Insomnia</strong>, outils d'envoi de <strong>requêtes http</strong>.</p>}} 
                                boutons={{id:"boutonVoir3", texte:"Voir"}}
                                overlay={{id:"overlay3", croixId:"croixBlogAPI", 
                                    src:BlogAPI, 
                                    imgId:"blogAPIBigScreen", imgAlt:"blog api grand"}}                                
                                />    
                <PortfolioItem type="video"
                                media={{src:MielConnect, alt:"une video de miel connect", id:"MCvideo", poster:MCVideo}}
                                texte={{titre:"Miel Connect", paragraphe:<p><strong>Intégration</strong> d'une maquette faite par des <strong>designers</strong> d'un site fictif selon cahier des charges. Intégration avec <strong>Wordpress</strong> et <strong>Elementor</strong>.</p>}} 
                                boutons={[{id:"bouton4Lire", texte:"Lire"},
                                            {id:"bouton4Pause", texte:"Pause"},
                                            {id:"bouton4Agrandir", texte:"Agrandir"}
                                        ]}  
                                refItem={refMielConnect}                                      
                                /> 
                <PortfolioItem type="image"
                                media={{src:RCMortagne, alt:"Racing club de Mortagne", id:"rcMortagne"}}
                                texte={{titre:"RC Mortagne", paragraphe:<p>Mon projet de fin de formation, faire le <strong>design</strong> puis <strong>développer</strong> le site fictif d'une équipe de football. Site réalisé avec le framework de PHP : <strong>Symfony</strong>. Base de données avec <strong>MySQL</strong>. </p>}} 
                                boutons={{id:"boutonVoir5", texte:"Voir"}}
                                overlay={{id:"overlay4", croixId:"croixRCMortagne", 
                                    src:RCMortagneLactu, 
                                    imgId:"rcMortagneBigScreen", imgAlt:"l'actu du RC Mortagne"}} 
                                refItem={refRcMortagne}
                                />                                   
                <PortfolioItem type="lien"
                                media={{src:PortfolioImg, alt:"portfolio", id:"portfolio"}}
                                texte={{titre:"Portfolio alternatif", paragraphe:<p>Site portfolio contenant une <strong>mouse-trail</strong>, un <strong>carousel</strong> de photos de projets, une <strong>roue des compétences</strong> ainsi qu'un formulaire de <strong>contact</strong> flottant.</p>}} 
                                lien={{href:"https://sylvainflt.github.io/portfolioAlt2/", id:"boutonVoir6", texte:"Voir"}}                                                               
                                refItem={refPortfolioAlt}
                                />
                <PortfolioItem type="video"
                                media={{src:BalanceAppVid, alt:"liste de dépenses", id:"balance", poster:BalanceAppImg}}
                                texte={{titre:"Liste de dépenses", paragraphe:<p>Application de calcul de dépenses, pratique de <strong>React</strong> avec le hook <strong>reducer</strong> ainsi que le hook <strong>context</strong>.</p>}} 
                                boutons={[{id:"bouton4Lire", texte:"Lire"},
                                            {id:"bouton4Pause", texte:"Pause"},
                                            {id:"bouton4Agrandir", texte:"Agrandir"}
                                        ]}  
                                refItem={refBalanceApp}                                      
                                />                   
                <PortfolioItem type="video"
                                media={{src:EmojeTaCiteVid, alt:"appli emojeTaCite", id:"emojeTaCite", poster:EmojeTaCiteImg}}
                                texte={{titre:"Appli EmojeTaCite", paragraphe:<p>Application de recherche de <strong>citations</strong>, avec possibilités de commenter avec des <strong>emojis</strong> et d'envoyer une carte par <strong>e-mail</strong>. Réalisé en Javascript, elle utilise l' API <strong>wikiquote</strong>.</p>}} 
                                boutons={[{id:"bouton4Lire", texte:"Lire"},
                                            {id:"bouton4Pause", texte:"Pause"},
                                            {id:"bouton4Agrandir", texte:"Agrandir"}
                                        ]}  
                                refItem={refEmojeTaCiteApp}                                      
                                />                                                                                    
            </div>                                                
            <img src={UpArrow} id="scrollToLogos" style={{display: displayBackToLogos}} onClick={backToLogos} alt="fleche pour remonter aux logos"/>                     
        </div>   
        <div className="skew-c2"></div>     
    </section>
  )
}

export default Portfolio