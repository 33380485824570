import React, {useState, useRef} from 'react'
import './fullCalendar.css'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import frLocale from '@fullcalendar/core/locales/fr'
// import the third-party stylesheets directly from your JS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import CustomModal from './CustomModal'

//import dateFormat from 'dateformat'

//import { createEventId } from './event-utils'

const FullCalendarComponent = () => {
    
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const calendarRef = useRef(null);

  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [color, setColor] = useState("#0000ff");

  const [validate, setValidate] = useState({
    titreState: '',
    detailsState: ''
  });

  const handleCloseModal = () => {
    handleClose();
    setModal(false);
  };

  function storeEvents() {
    let calendarApi = calendarRef.current.getApi();                 
    let newEventTab = calendarApi.getEvents();
                  
    console.log(newEventTab);
    localStorage.setItem('calendarEvents', JSON.stringify(newEventTab));  
  }

  function handleDateSelect(selectInfo) {
    // console.log(selectInfo.view.type);
    if (
      selectInfo.view.type === "timeGridWeek" ||
      selectInfo.view.type === "timeGridDay"
    ) {
      selectInfo.view.calendar.unselect();
      setState({ selectInfo, state: "créer" });
      
      // console.log(selectInfo);
      setStart(selectInfo.start);
      setEnd(selectInfo.end);
      setModal(true);
    }
  }
  function renderEventContent(eventInfo) {
    return (
      <div style={{ overflow: "hidden" }}>
        {/* <b>{eventInfo.timeText}</b> */}
        <i
          style={{
            whiteSpace: "normal",
            textOverflow: "ellipsis",
            wordBreak: "break-word"
          }}
        >
          {eventInfo.event.title}
        </i>
      </div>
    );
  }
  function handleEventClick(clickInfo) {
    console.log(clickInfo);
    setState({ clickInfo, state: "maj" });
    
    setTitle(clickInfo.event.title);
    setDetails(clickInfo.event.extendedProps.details);
    setColor(clickInfo.event.backgroundColor);
    setStart(clickInfo.event.start);
    setEnd(clickInfo.event.end);

    setModal(true);
    
  }
  
  function handleEventDrop(checkInfo) {
    
    setState({ checkInfo, state: "déplacer" });
    
    setConfirmModal(true);
    
  }
  function handleEventResize(checkInfo) {
    
    setState({ checkInfo, state: "redimensionner" });

    setConfirmModal(true);
    
  }

  function resizeOrDropEvent() {
    console.log(state);
    
    storeEvents();

    setConfirmModal(false);  
  }

  function handleEdit() {
    console.log(state);
        
    let validateFormOK = validateForm();    

    if(validateFormOK) {
      
      state.clickInfo.event.mutate({
        standardProps: { title }
      });
      state.clickInfo.event.mutate({
        extendedProps: { details }
      }); 
      state.clickInfo.event.setProp('backgroundColor', color);
      state.clickInfo.event.setProp('borderColor', color);

      storeEvents();
                    
      handleClose();
    }
  }
  function handleSubmit() {
    
    let validateFormOK = validateForm();    

    if(validateFormOK) {
              
      let calendarApi = calendarRef.current.getApi();
      let lastEvent = calendarApi.getEvents().pop();
      let nextEventId = 0;
      if(lastEvent) nextEventId = +lastEvent.id + 1;      

      const newEvent = {
        id: nextEventId,
        title,
        details,
        color,
        start: state.selectInfo?.startStr || start.toISOString(),
        end: state.selectInfo?.endStr || end.toISOString(),
        allDay: state.selectInfo?.allDay || false
      };      
    
      calendarApi.addEvent(newEvent); 

      storeEvents();             

      handleClose();
    }     
      
  }
 
  function handleDelete() {
    // console.log(JSON.stringify(state.clickInfo.event));
    // console.log(state.clickInfo.event.id);
    state.clickInfo.event.remove();

    storeEvents();
    
    handleClose();
  }
  function handleClose() {
    setTitle("");
    setDetails("");
    setColor("#0000ff");
    setStart(new Date());
    setEnd(new Date());    
    setState({});
    setModal(false);
    setValidate({
      titreState: '',
      detailsState: ''
    })   
  }
    
  const [state, setState] = useState({});

  function validateTitre(e) {

    if(e.target.value !== '') {
      validate.titreState = 'has-success';
    } else {
      validate.titreState = 'has-danger';
      
    }
  }
  
  function validateDetails(e) {

    if(e.target.value !== '') {
      validate.detailsState = 'has-success';
    } else {
      validate.detailsState = 'has-danger';
    }
  }
  function validateForm(){

    if(title === ''){
      validate.titreState = 'has-danger';       
    } else {
      validate.titreState = 'has-success'; 
    }
    if(details === ''){
      validate.detailsState = 'has-danger';      
    } else {
      validate.detailsState = 'has-success';  
    }
    setValidate({
        titreState: validate.titreState,
        detailsState: validate.detailsState
    });
    if( validate.titreState === 'has-danger' || validate.detailsState === 'has-danger')
    {      
      return false;
    } 
    else {
      return true;
    }
  }  

    return (
      <section id="fullCalendar">
        <h2 className='calendarTitre'>CALENDRIER</h2>
        <FullCalendar
            ref={calendarRef}
            plugins={[ dayGridPlugin, timeGridPlugin, bootstrap5Plugin, interactionPlugin ]}
            themeSystem='bootstrap5'
            initialView="timeGridWeek"
            headerToolbar=
            {{
              start:'timeGridDay,timeGridWeek,dayGridMonth',
              center:'title',
              end:'prev,next'
            }}
            locale={frLocale}
            height="auto"
            weekends={false}
            slotMinTime="06:00:00"
            slotMaxTime="18:00:00"
            initialEvents={JSON.parse(!localStorage.getItem('calendarEvents')?.toString() ? JSON.stringify([]) : localStorage.getItem('calendarEvents')?.toString())}
            editable={true} 
            selectable={true}
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            //
            //dateClick={handleDateClick}              
        />
        <CustomModal
          title={state.state === "maj" ? "Mettre à jour l'évènement" : "Ajouter l'évènement"}
          isOpen={modal}
          toggle={handleCloseModal}
          onCancel={handleCloseModal}
          cancelText="Annuler"
          onSubmit={state.clickInfo ? handleEdit : handleSubmit}
          submitText={state.clickInfo ? "Mettre à jour" : "Enregistrer"}
          onDelete={state.clickInfo && handleDelete}
          deleteText="Supprimer"
        >
          <Form>
            <FormGroup>
              <Label for="details">Titre</Label>
              <Input
                type="text"
                name="title"
                placeholder="le titre de l'évènement"                
                valid={ validate.titreState === 'has-success' }
                invalid={ validate.titreState === 'has-danger' }
                value={title}
                onChange={(e) => {
                    validateTitre(e);
                    setTitle(e.target.value);                  
                  }
                }
              />
              <FormFeedback>Vous devez obligatoirement entrer un titre.</FormFeedback>
            </FormGroup> 
            <FormGroup>
              <Label for="details">Details</Label>
              <Input
                type="text"
                name="details"
                placeholder="les détails"                
                valid={ validate.detailsState === 'has-success' }
                invalid={ validate.detailsState === 'has-danger' }
                value={details}
                onChange={(e) => {
                  validateDetails(e);
                  setDetails(e.target.value)}
                }
              />
              <FormFeedback>Vous devez obligatoirement entrer des détails.</FormFeedback>
            </FormGroup>           
            <FormGroup>
              <Label for="color">Couleur de fond</Label>
              <Input
                type="color"
                name="color"
                placeholder="la couleur de fond"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </FormGroup>            
          </Form>               
        </CustomModal>
        <CustomModal
          title={state.state === "redimensionner" ? "Redimensionner l'évènement" : "Déplacer l'évènement"}
          isOpen={confirmModal}
          toggle={() => {
            state.checkInfo.revert();
            setConfirmModal(false);
          }}
          onCancel={() => {
            state.checkInfo.revert();
            setConfirmModal(false);
          }}
          cancelText="Annuler"
          onSubmit={resizeOrDropEvent}
          submitText={"OK"}
        >
          Voulez vous {state.state} l'évènement ?
        </CustomModal>
      </section>
  )
}

export default FullCalendarComponent