import React from 'react'
import CV from '../../assets/Sylvain_FOUCAULT_CV.pdf'

const CTA = () => {
  return (
    
        <a href={CV} target="_blank" rel="noreferrer" id="boutonCV">Voir mon CV</a>
    
  )
}

export default CTA